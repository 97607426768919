import React from "react"
import { graphql } from "gatsby"
import NewsCategory from "./news-category"

const Page = ({ data, pageContext }) => {
  return (
    <NewsCategory data={data} pageContext={pageContext} />
  )
}

export default Page

export const pageQuery = graphql`
  query($termId: Int!, $skip: Int!, $limit: Int!) {
    posts: allWordpressNews(
      limit: $limit,
      skip: $skip,
      sort: {order: DESC, fields: date_gmt},
      filter: {news_tag_ids: {in: [$termId]}, status: {eq: "publish"}}
    ) {
      nodes {
        ...NewsItem
      }
    }

    term: wordpressNewsTag(term_id: {eq: $termId}) {
      term_id
      taxonomy
      slug
      name
      count
      yoast_title
      yoast_json_ld
      yoast_meta {
        content
        name
        property
      }
    }

    mainPage: wordpressMainPage(name: {eq: "news-superbom"}) {
      ...MainPageFull
    }
  }
`
